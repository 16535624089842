import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'https://os.alipayobjects.com/rmsportal/mlcYmsRilwraoAe.svg',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      { name: 'item0', a: { children: 'Link 1', href: '' } },
      { name: 'item1', a: { children: 'Link 2', href: '' } },
      { name: 'item2', a: { children: 'Link 3', href: '' } },
      { name: 'item3', a: { children: 'Link 4', href: '' } },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: 'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
  },
  content: {
    className: 'banner0-content',
    children: 'Marco Corradin',
  },
  button: { className: 'banner0-button', children: 'Learn More' },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Darkweb | Cybercrime | Blockchain | Tech ' }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              'speaker.svg',
            altName: 'Public Speaker',
          },
          title: { className: 'content0-title', title: 'Public Speaker' },
          content: { children: 'Vergeet statische lezingen. Ik neem in begrijpbare taal het publiek mee in nieuwe technologische ontwikkelingen tijdens een live demonstratie.' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              'teacher.svg',
            altName: 'Trainer',
          },
          title: {
            className: 'content0-title',
            title: 'Trainer',
          },
          content: { children: 'Op maat gemaakte trainingen op het gebied van darkweb, blockchain, OSINT en ethical hacking voor beginners tot experts.' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              'cyber.svg',
            altName: 'Tech Innovator',
          },
          title: { className: 'content0-title', title: 'Tech Innovator', },
          content: { children: 'Bedrijven en organisaties helpen richting toekomstbestendigheid op het gebied van technologische ontwikkelingen.' },
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: 'Portfolio', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: '',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'security_cafe.jpeg',
            altName: 'Security Cafe'
          },
          content: { children: 'Lezing @ Security Café' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'raadsheren.jpg',
            altName: 'Raadsheren Nederland'
          },
          content: { children: 'Lezing @ Raadsheren Nederland' },
        },
      },
      
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'CSI-NL3.jpg',
            altName: 'CSI:NL Politie'
          },
          content: { children: 'Lezing @ CSI:NL Politie' },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'training1.jpg',
            altName: 'Darkweb Training'
          },
          content: { children: 'Training @ classified' },
        },
      },

      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'training2.jpg',
            altName: 'Darkweb Training'
          },
          content: { children: 'Training @ also classified :-)' },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'rechtspraak.png',
            altName: 'de Rechtspraak'
          },
          content: { children: 'Lezing @ de Rechtspraak' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'vebon-novb.jpg',
            altName: 'VEBON-NOVB'
          },
          content: { children: 'Lezing @ VEBON' },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'diex.jpg',
            altName: 'Digitale Expertise DiEx'
          },
          content: { children: 'Training @ DiEx' },
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.1 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'Contact',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: 'Interesse in een lezing, training of op zoek naar meer informatie?',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'mail.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'E-mail' },
          content: {
            className: 'content3-content',
            children:
              'email.svg',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'linkedin.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'LinkedIn' },
          content: {
            className: 'content3-content',
            children:
              'https://www.linkedin.com/in/marco-corradin/',
          },
        },
      },
      
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg',
        },
        content: {
          className: 'slogan',
          children: 'Animation specification and components of Ant Design.',
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品' },
        content: {
          children: (
            <>
              <p>
                {' '}
                <a href="https://marcocorradin.com">产品更新记录</a>{' '}
              </p>{' '}
              <p>
                {' '}
                <a href="https://marcocorradin.com">API文档</a>{' '}
              </p>{' '}
              <p>
                {' '}
                <a href="https://marcocorradin.com">快速入门</a>{' '}
              </p>{' '}
              <p>
                {' '}
                <a href="https://marcocorradin.com">参考指南</a>{' '}
              </p>
            </>
          ),
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        content: {
          children: (
            <>
              <p>
                {' '}
                <a href="https://marcocorradin.com">FAQ</a>{' '}
              </p>{' '}
              <p>
                {' '}
                <a href="https://marcocorradin.com">联系我们</a>{' '}
              </p>
            </>
          ),
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '资源' },
        content: {
          children: (
            <>
              <p>
                {' '}
                <a href="https://marcocorradin.com">Ant Design</a>{' '}
              </p>{' '}
              <p>
                {' '}
                <a href="https://marcocorradin.com">Ant Design</a>{' '}
              </p>{' '}
              <p>
                {' '}
                <a href="https://marcocorradin.com">Ant Design</a>{' '}
              </p>{' '}
              <p>
                {' '}
                <a href="https://marcocorradin.com">Ant Design</a>{' '}
              </p>
            </>
          ),
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <span>
          ©2019 by <a href="https://marcocorradin.com">Marco Corradin</a> All Rights
          Reserved
        </span>
      </>
    ),
  },
};
