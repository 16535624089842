import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// ...

class Head extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Marco Corradin - Public Speaker, Trainer en Tech innovator</title>
          <meta name="description" content="Marco Corradin is een expert in innovatie en technologie. Laat je meenemen tijdens zijn lezingen en trainingen in de wereld van darkweb, blockchain en hacking." />
        </Helmet>
        {/* ... */}
      </div>
    );
  }
}

export default Head;