import React from 'react';
// import { Button, Icon } from 'antd';
import { Icon } from 'antd';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
// import { isImg } from './utils';
import marcoFace from '../assets/images/techforce_marco_cropped.jpg';
import letterM from '../assets/images/letters/m.svg';
import letterA from '../assets/images/letters/a.svg';
import letterR from '../assets/images/letters/r.svg';
import letterC from '../assets/images/letters/c.svg';
import letterO from '../assets/images/letters/o.svg';
import letterD from '../assets/images/letters/d.svg';
import letterI from '../assets/images/letters/i.svg';
import letterN from '../assets/images/letters/n.svg';

import { Row, Col } from 'antd';

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      
      <div {...currentProps} {...dataSource.wrapper}>
      
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >

          <Row type="flex" style={{height: '100%'}}>
            <Col span={12} className="center" style={{height: '100%'}}>
              <div style={{verticalAlign: 'middle'}}>
            <img className="marco-face" src={marcoFace} alt="Marco Corradin" title="Marco Corradin" />
            </div>
            </Col>
            <Col span={12} className="center">
              <div key="content" className="letters" style={{height: '100%'}}>

              
              <img src={letterM} width="7%" alt="M" title="M"></img>
              <img src={letterA} width="7%" alt="a" title="a"></img>
              <img src={letterR} width="7%" alt="r" title="r"></img>
              <img src={letterC} width="7%" alt="c" title="c"></img>
              <img src={letterO} width="7%" alt="o" title="o"></img>
              <img src={letterC} width="7%" style={{marginLeft: '5%'}} alt="C" title="C"></img>
              <img src={letterO} width="7%" alt="o" title="o"></img>
              <img src={letterR} width="7%" alt="r" title="r"></img>
              <img src={letterR} width="7%" alt="r" title="r"></img>
              <img src={letterA} width="7%" alt="a" title="a"></img>
              <img src={letterD} width="7%" alt="d" title="d"></img>
              <img src={letterI} width="7%" style={{marginLeft: '-2%', marginRight: '-2%'}} alt="i" title="i"></img>
              <img src={letterN} width="7%" alt="n" title="n"></img>
              </div>

              {/* This is for SEO */}
              <h1 style={{display: 'none'}}>Marco Corradin - Public Speaker, Trainer en Tech innovator</h1>
              

            </Col>
          </Row>
{/*
          <div key="title" {...dataSource.title}>
            {typeof dataSource.title.children === 'string' &&
            dataSource.title.children.match(isImg) ? (
              <img src={marcoFace} width="100%" alt="img" />
            ) : (
              dataSource.title.children
            )}
          </div>
            
          <div key="content" {...dataSource.content}>
            {dataSource.content.children}
          </div>
          <Button ghost key="button" {...dataSource.button}>
            {dataSource.button.children}
          </Button>
          */}
        </QueueAnim>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          <Icon type="down" />
        </TweenOne>
      </div>
    );
  }
}
export default Banner;
